import React from "react";
import { createRoot, Root } from "react-dom/client";
import { enableMapSet } from "immer";
enableMapSet();

import produce from "immer";

// ABC Imports
import acto from "@abcnews/alternating-case-to-object";
import { whenOdysseyLoaded } from "@abcnews/env-utils";
import { getMountValue, selectMounts } from "@abcnews/mount-utils";
import { loadScrollyteller, ScrollytellerDefinition } from "@abcnews/scrollyteller";

import App from "./components/App";
import Preview from "./components/Preview";

const MOUNT_NAME = "interactiveukraineglobalimpact";

let root: Root;
let scrollyData: ScrollytellerDefinition<any>[] = [];

const init = async () => {
  await whenOdysseyLoaded;

  const [appMountEl] = selectMounts(MOUNT_NAME);

  if (!appMountEl) {
    console.error(`No mount point found. Consider adding ${MOUNT_NAME}`);
    return;
  }

  const mountConfig = acto(appMountEl.id);

  root = createRoot(appMountEl);

  // Check if we are in "builder" mode
  if (mountConfig.preview) {
    root.render(<Preview />);
    return;
  } // Otherwise Odyssey

  // Full bleed to edges of screen
  appMountEl.classList.add("u-full");

  const scrollytellerEls = selectMounts("scrollyteller", { markAsUsed: false });

  for (const el of scrollytellerEls) {
    const mountId = getMountValue(el);
    const config = acto(mountId);
    const { name } = config;
    if (!name) continue;

    const data = loadScrollyteller(name + "", "u-full");

    // Index each panel
    data.panels = data.panels.map((d: any, index) => ({
      ...d,
      data: { ...d.data, index, isFor: name }
    }));

    scrollyData = produce(scrollyData, (draftState: any) => {
      draftState.push(data);
    });
  }

  renderApp();
};

function renderApp() {
  root.render(<App scrollyData={scrollyData} />);
}

// Initial render
init();

// Reload on save support
if (module.hot) {
  module.hot.accept("./components/App", () => {
    try {
      renderApp();
    } catch (err: any) {
      import("./components/ErrorBox").then(({ default: ErrorBox }) => {
        root.render(<ErrorBox error={err} />);
      });
    }
  });
}

if (process.env.NODE_ENV === "development") {
  console.debug(
    `[interactive-ukraine-global-impact] public path: ${__webpack_public_path__}`
  );
}
