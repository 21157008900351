import React, { useRef, useEffect, useState } from "react";
import { encode as b36enc, decode as b36dec } from "@abcnews/base-36-props";
// import cloneDeep from "lodash.clonedeep";

import CustomGlobe from "../CustomGlobe";
import styles from "./styles.scss";

const highlightCountriesSet = new Set<string>([]);

export type Label = {
  lat: number;
  lng: number;
  text: string;
};

const GlobalStage = ({ orbitalControls = true }) => {
  const thisEl = useRef<HTMLDivElement>(null);
  const [pointOfView, setPointOfView] = useState<any>();
  const [globeConfig, setGlobeConfig] = useState<any>();
  const [globeConfigEncoded, setGlobeConfigEncoded] = useState("");
  const [highlightCountries, setHighlightCountries] = useState<string[]>([]);
  const [labelData, setLabelData] = useState<Label[]>([]);

  function roundNumber(num: number, dec: number): number {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
  }

  const onSpin = data => {
    const latitude = roundNumber(data.lat, 2);
    const longitude = roundNumber(data.lng, 2);
    const altitude = roundNumber(data.altitude, 2);
    // const config = { lat: latitude, lon: longitude, alt: altitude };
    setGlobeConfig(prevConfig => ({
      ...prevConfig,
      lat: latitude,
      lon: longitude,
      alt: altitude
    }));
  };

  const onCountryClick = data => {
    console.log(data);
    if (typeof data?.polygon?.properties?.name === "undefined") return;

    const name = data?.polygon?.properties?.name;

    const { lat, lng } = data.position;
    console.log(lat, lng, name);

    setLabelData(prevData => {
      if (highlightCountriesSet.has(name)) {
        const found = prevData.find(label => label.text === name);
        if (found) return [...prevData.filter(label => label.text !== name)];
        return [
          ...prevData,
          { lat: roundNumber(lat, 2), lng: roundNumber(lng, 2), text: name }
        ];
      }
      return [...prevData];
    });

    if (highlightCountriesSet.has(name)) highlightCountriesSet.delete(name);
    else highlightCountriesSet.add(name);

    setHighlightCountries([...highlightCountriesSet]);
    setGlobeConfig(prevConfig => {
      // const labelClone = cloneDeep(labelData);
      const newLabelData: any = [];
      labelData.forEach((label: any) => {
        const { lat, lng, text } = label;
        newLabelData.push({ lat, lng, text });
      });

      return {
        ...prevConfig,
        highlightCountries: [...highlightCountriesSet],
        labelData: [...newLabelData]
      };
    });
  };

  const onMount = async () => {
    const el = thisEl.current;
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    if (typeof globeConfig === "undefined") return;
    const encodedConfig = b36enc(globeConfig);
    setGlobeConfigEncoded(encodedConfig);
  }, [globeConfig]);

  // useEffect(() => {
  //   console.log(globeConfig);
  // }, [globeConfig]);

  return (
    <div ref={thisEl} className={`${styles.root} u-full`}>
      <CustomGlobe
        pointOfView={pointOfView}
        orbitalControls={orbitalControls}
        onSpin={onSpin}
        onCountryClick={onCountryClick}
        highlightCountries={highlightCountries}
        htmlElementsData={labelData}
      />
      <input
        type="text"
        className={styles.configHash}
        value={`#markCONF${globeConfigEncoded}`}
        readOnly
      ></input>
    </div>
  );
};

export default GlobalStage;
