import React from "react";
import styles from "./styles.scss";

import GlobalStage from "../GlobalStage";

const App = () => {
  return <div className={styles.root}>
    <GlobalStage />
  </div>;
};

export default App;
