import * as THREE from "three";

function getMeshOutline({ multipyer }) {
  // Create outline object
  const outlineGeo = new THREE.SphereGeometry(99, 64, 64);

  // Bacface culling sphere
  const outlineMat = new THREE.MeshBasicMaterial({
    color: 0xbfd5e0,
    side: THREE.BackSide
  });

  const outline = new THREE.Mesh(outlineGeo, outlineMat);

  // Scale the object up to have an outline
  outline.scale.multiplyScalar(multipyer);

  return outline;
}

export { getMeshOutline };
