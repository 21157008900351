export const getThreeObjects = globeEl => {
  const Renderer = globeEl.current?.renderer;
  const Scene = globeEl.current?.scene;
  const Controls = globeEl.current?.controls;
  const Camera = globeEl.current?.camera;

  const renderer = new Renderer();
  const scene = new Scene();
  const camera = new Camera();
  const controls = new Controls();
  const globe = globeEl.current;

  return { renderer, scene, camera, controls, globe };
};
