// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@abcnews/aunty/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@abcnews/aunty/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,body{margin:0}.YtNmyc{position:fixed;right:10px;bottom:10px;background-color:#fff;color:#000;min-width:300px;min-height:10px;padding:4px 8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"config-hash": "YtNmyc",
	"configHash": "YtNmyc"
};
export default ___CSS_LOADER_EXPORT___;
