import * as topojson from "topojson-client";
import * as topojsonSimplify from "topojson-simplify";
const topo = { ...topojson, ...topojsonSimplify };

const getSimplifiedLand = async (simplification: number, type: string) => {
  const countries = await import("../data/countries-50m.json");

  const pre = topo.presimplify(countries as any);
  let min_weight = topo.quantile(pre, simplification);

  const simplified = topo.simplify(pre, min_weight);

  const countries50Filtered = topo.filter(
    simplified,
    topo.filterWeight(simplified, min_weight)
  );

  const borders = topojson.mesh(
    countries50Filtered as any,
    countries50Filtered.objects.countries as any,
    (a, b) => a !== b
  );

  const objects =
    type === "countries"
      ? countries50Filtered.objects.countries
      : countries50Filtered.objects.land;

  const geoFiltered: any = topo.feature(countries50Filtered, objects);

  // For some stupid reason Antarcica gets filtered out
  // by topojson.filterWeight. So we have to add it back in manually.
  if (type === "countries") {
    const geoUnfiltered: any = topo.feature(simplified, simplified.objects.countries);
    const antarctica = geoUnfiltered.features.find(f => f.id === "010");
    geoFiltered.features.push(antarctica);
  }

  return {
    geoFiltered,
    borders
  };
};

export { getSimplifiedLand };

// ---

// const worker = new Worker(new URL("./workers/topoSimplifyWorker.ts", import.meta.url));

// const getSimplifiedLand = async (simplification: number, type: string) => {
//   worker.postMessage({ simplification, type });

//   async function waitForWorkerMessage() {
//     return new Promise(resolve => {
//       worker.onmessage = ({ data: { answer } }) => {
//         resolve(answer);
//       };
//     });
//   }
//   return await waitForWorkerMessage();
// };
