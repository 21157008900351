import React, { useRef, useEffect, useState, useMemo, memo } from "react";
import Globe from "react-globe.gl";
import * as THREE from "three";

// Local
import { getThreeObjects } from "./utils/getThreeObjects";
import { getSimplifiedLand } from "./utils/topoSimplify";
import { getGeoLines } from "./utils/getGeoLines";
import { getMeshOutline } from "./utils/getMeshOutline";
import { addPostProcessing } from "./utils/addPostProcessing";

// Local library imports
import { useWindowSize } from "./lib/useWindowSize";

// Type imports
import type { Size } from "./lib/useWindowSize";

// Styles
import styles from "./styles.scss";

// THREE materials
const globeMaterial = new THREE.MeshBasicMaterial({
  color: "#BFD5E0"
  //  wireframe: true,
  //  wireframeLinewidth: 2,
});

const landMaterial = new THREE.MeshBasicMaterial({
  // color: "white"
  // side: THREE.BackSide
  // transparent: true,
  // opacity: 0.3
  // side: THREE.DoubleSide
  wireframe: true,
  wireframeLinewidth: 2
});

export type GeoCoords = { lat: number; lng: number; altitude: number };

interface GlobalStageProps {
  orbitalControls?: boolean;
  initialPosition?: GeoCoords;
  pointOfView?: GeoCoords;
  highlightCountries?: string[];
  transitionDuration?: number;
  htmlElementsData?: any[];
  onSpin?: (data: any) => void;
  onCountryClick?: (data: any) => void;
}

// Random test arcs
const N = 20;
const arcsData = [...Array(N).keys()].map(() => ({
  startLat: (Math.random() - 0.5) * 180,
  startLng: (Math.random() - 0.5) * 360,
  endLat: (Math.random() - 0.5) * 180,
  endLng: (Math.random() - 0.5) * 360,
  color: [["red"][Math.round(Math.random() * 0)], ["red"][Math.round(Math.random() * 0)]]
}));

// import placesImport from "./data/country-cent.geo.json";
// const places: any = placesImport;
// console.log(places);

const GlobalStage: React.FC<GlobalStageProps> = ({
  orbitalControls = true,
  initialPosition = { lat: 49, lng: 32, altitude: 2.0 },
  pointOfView,
  highlightCountries = [],
  htmlElementsData = [],
  transitionDuration = 0,
  onSpin = (data: any) => {},
  onCountryClick = (data: any) => {}
}) => {
  const globeEl = useRef<any>();

  // Globals
  const globals = useRef<any>({}); // For use later

  const [landPolygons, setLandPolygons] = useState<any>([]);
  const [arcs, setArcs] = useState<any>([]);

  // Three.js objects
  const [globe, setGlobe] = useState<any>(null);
  const [camera, setCamera] = useState<any>(null);
  // const [controls, setControls] = useState<any>(null);

  const [currentGeoCoords, setCurrentGeoCoords] = useState<GeoCoords>(initialPosition);

  // Hooks
  const size: Size = useWindowSize();

  const onMount = async () => {
    const countriesGeo = await import("./data/CNTR_BN_20M_2020_4326.geo.json");

    // Get three.js objects from react-globe.gl
    const { renderer, scene, camera, controls, globe } = getThreeObjects(globeEl);

    // Fixes some lines going funny
    camera.near = 1;

    // Set initial position
    globe.pointOfView(initialPosition, 0);

    // Three custom config
    controls.minDistance = 120;
    controls.maxDistance = 1000;

    // Set as state
    setGlobe(globe);
    setCamera(camera);

    const worldOutlineBackface = getMeshOutline({ multipyer: 1.028 });
    worldOutlineBackface.name = "worldOutlineBackface";
    scene.add(worldOutlineBackface);

    // Alternate implementation of geo lines to test a faster method.
    // This one doesn't calculate all the tesselations etc.
    const countryLines = getGeoLines(countriesGeo.features);
    // Rotate 270 degrees to match the globe
    countryLines.rotation.y = 270 * (Math.PI / 180);
    scene.add(countryLines);

    // Set quick land polygons first then calculate detailed ones
    // setLandPolygons(((await getSimplifiedLand(0.01, "countries")) as any).geoFiltered.features);
    // setLandPolygons(((await getSimplifiedLand(0.06, "countries")) as any).geoFiltered.features);
    // setLandPolygons(((await getSimplifiedLand(0.1, "countries")) as any).geoFiltered.features);

    const simplifiedLand: any = await getSimplifiedLand(0.139, "countries");
    setLandPolygons(simplifiedLand.geoFiltered.features);

    // setArcs(arcsData);

    // Add post processing
    // Currently causing issues with z-fighting and depth-buffering
    // or something.
    // addPostProcessing(globe, renderer, scene, camera);
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    const { controls } = getThreeObjects(globeEl);
    controls.enabled = orbitalControls;
  }, [orbitalControls]);

  useEffect(() => {
    setTimeout(() => {
      globeEl.current?.pointOfView(pointOfView, transitionDuration);
    }, 100); // Wait a bit for initial spin
  }, [pointOfView]);

  useEffect(() => {
    onSpin(currentGeoCoords);
  }, [currentGeoCoords]);

  return (
    <div className={styles.root}>
      <Globe
        ref={globeEl}
        backgroundColor="rgba(0,0,0,0)"
        showGlobe={true}
        globeMaterial={globeMaterial}
        showAtmosphere={true}
        atmosphereAltitude={0.2}
        polygonsData={landPolygons}
        polygonCapMaterial={(feature: any) => {
          // console.log(feature.properties.name);
          const material = new THREE.MeshBasicMaterial({
            color: highlightCountries.includes(feature.properties.name)
              ? "#00297E"
              : "white"

            // side: THREE.BackSide
            // transparent: true,
            // opacity: 0.3
            // side: THREE.DoubleSide
            // wireframe: true,
            // wireframeLinewidth: 2
          });

          material.polygonOffset = true;
          material.depthTest = true;
          material.polygonOffsetFactor = 1;
          material.polygonOffsetUnits = 1;

          return material;
        }}
        polygonSideColor={() => "rgba(0, 0, 0, 0)"}
        polygonAltitude={0.019}
        animateIn={false}
        polygonsTransitionDuration={0}
        polygonCapCurvatureResolution={18}
        // polygonStrokeColor={() => "rgba(100, 100, 100, 1)"}
        // polygonCapColor={(features: any) => {
        //   // console.log(features);
        //   if (features.id === "104") return "red";
        //   return "rgba(255, 255, 255, 1)";
        // }}
        showGraticules={true}
        enablePointerInteraction={true}
        arcsData={arcs}
        arcColor={"color"}
        // arcAltitudeAutoScale={0.4}
        // arcAltitude={0.5}
        // arcStroke={0.1}
        waitForGlobeReady={true}
        width={size.width}
        height={size.height}
        htmlElementsData={htmlElementsData}
        htmlElement={(d: any) => {
          const el = document.createElement("div");
          el.innerHTML = d.text;
          el.style.color = "white";
          el.style.border = "1px solid black";
          el.style.background = "black";
          el.style.padding = "1px 4px";
          el.style.fontFamily = "'ABC Sans', sans-serif";
          el.style.fontSize = "13px";
          // el.style.width = `${d.size}px`;

          // el.style['pointer-events'] = 'auto';
          // el.style.cursor = 'pointer';
          // el.onclick = () => console.info(d);
          return el;
        }}
        onZoom={() =>
          globe &&
          camera &&
          setCurrentGeoCoords(
            globe.toGeoCoords({
              x: camera.position.x,
              y: camera.position.y,
              z: camera.position.z
            })
          )
        }
        onPolygonClick={(polygon: object, event: MouseEvent, { lat, lng, altitude }) => {
          onCountryClick({ polygon, event, position: { lat, lng, altitude } });
        }}
      />
    </div>
  );
};

export default GlobalStage;

// Functions to move to their own file eventually

// *********************************** DEAD CODE GRAVEYARD

// const sphereGeometry = new THREE.SphereGeometry(99, 64, 64);

// const sphere = new THREE.Mesh(
//   sphereGeometry,
//   new THREE.MeshBasicMaterial({
//     color: "#BFD5E0"
//     // wireframe: true,
//     // wireframeLinewidth: 2
//   })
// );

// --------

// const c = globe.getCoords(49, 32, 0.5);
// const g = globe.toGeoCoords({
//   x: camera.position.x,
//   y: camera.position.y,
//   z: camera.position.z
// });

// console.log(c, g);

// -------

// controls.update() must be called after any manual changes to the camera's transform
// camera.position.set(0, 0, -600);
// controls.enabled = false;

// const light = new THREE.AmbientLight("white", 1000); // soft white light
// scene.add(light);

// controls.update();
