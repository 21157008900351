import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.scss";
import Scrollyteller from "@abcnews/scrollyteller";
import { getMountValue, selectMounts } from "@abcnews/mount-utils";
import acto from "@abcnews/alternating-case-to-object";
import { Portal } from "react-portal";
import { encode as b36enc, decode as b36dec } from "@abcnews/base-36-props";
import * as portals from "react-reverse-portal";

import CustomGlobe, { GeoCoords } from "../CustomGlobe";

import type { Label } from "../GlobalStage";

type Config = {
  lat: number;
  lon: number;
  alt: number;
  highlightCountries: string[];
  labelData: Label[];
};

interface AppProps {
  scrollyData: any;
}

// Capture initial state for each scrollyteller
const stageConfigs = new Map();

const App: React.FC<AppProps> = ({ scrollyData }) => {
  // const [progress, setProgress] = useState<any>(null);
  const [pointOfView, setPointOfView] = useState<GeoCoords>();
  const [visibleStageName, setVisibleStageName] = useState<string>();
  const [highlightCountries, setHighlightCountries] = useState<string[]>();
  const [labelData, setLabelData] = useState<Label[]>();

  // Reverse portal
  const portalNode = React.useMemo(() => portals.createHtmlPortalNode(), []);

  const onIntersection = entries => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const id = getMountValue(entry.target as any);
        const config = acto(id);
        const { name } = config;

        if (typeof name === "undefined" || name === null) continue;

        setVisibleStageName(name + "");

        const observedStage = stageConfigs.get(name);
        if (typeof observedStage === "undefined") continue;

        const { lat, lon, alt } = observedStage;
        setPointOfView({ lat, lng: lon, altitude: alt });
        // TODO if doing multiple scrollytellers again, set other config
        // or make a set config function.
      }
    }
  };

  const onMount = () => {
    const intersectionObserver = new IntersectionObserver(onIntersection);

    scrollyData.forEach(element => {
      intersectionObserver.observe(element.mountNode);
    });
  };

  const onMarker = data => {
    const { conf, isFor, index } = data;
    if (typeof conf === "undefined") {
      console.error("Marker config undefined!");
      return;
    }

    const decodedConf: Config = b36dec(conf);

    stageConfigs.set(isFor, { index, ...decodedConf });

    // Only affect the current stage
    if (isFor !== visibleStageName) return;

    const { lat, lon, alt, highlightCountries, labelData } = decodedConf;

    console.log(decodedConf);

    setPointOfView({ lat: lat, lng: lon, altitude: alt });
    setHighlightCountries(highlightCountries);
    setLabelData(labelData);
  };

  // const onProgress = ({ pctAboveFold }) => setProgress(pctAboveFold);

  useEffect(() => {
    onMount();
  }, []);
  return (
    <div className={styles.root}>
      {/* Render our globe off screen first */}
      <portals.InPortal node={portalNode}>
        <CustomGlobe
          pointOfView={pointOfView}
          initialPosition={pointOfView}
          orbitalControls={false}
          transitionDuration={1700}
          highlightCountries={highlightCountries}
          htmlElementsData={labelData}
        />
      </portals.InPortal>

      {scrollyData.map((data, i) => {
        const { name } = acto(getMountValue(data.mountNode));

        return (
          <Portal node={data.mountNode} key={i}>
            <Scrollyteller<any>
              waypoint={0.8}
              panels={data.panels}
              {...data.config}
              onMarker={onMarker}
              // onProgress={onProgress}
            >
              <div className={`scrollystage-${name}`}></div>
            </Scrollyteller>
          </Portal>
        );
      })}
      {/* Portal and reverse portal to each scrollyteller */}
      <Portal node={document.querySelector(`.scrollystage-${visibleStageName}`)}>
        <portals.OutPortal node={portalNode} />
      </Portal>
    </div>
  );
};

export default App;
